import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

/**
 * NOTE: This 404 page is currently not accessible in production as Amplify has a reroute rule to index.
 */
const NotFoundPage: React.FC = () => (
  <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
)

export default NotFoundPage
